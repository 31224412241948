import * as React from 'react';

import { Container } from 'components/container/Container';

import s from './Footer.scss';

interface ISocial {
  icon: React.ReactNode;
  to: string;
}

interface IFooterProps {
  logo: React.ReactNode;
  social: ISocial[];
}

export const Footer = ({ logo }: IFooterProps) => (
  <div className={s.footer}>
    <Container>
      <div className={s.footer__content}>
        <a
          href="https://headways.digital"
          target="_blank"
          rel="noopener noreferrer"
        >
          
        </a>

        <ul className={s.footer__list}>
          <p>Headways Digital 2020</p>
          {/*{social.map((item) => (
            <li
              key={item.to}
              className={s.footer__item}
            >
              <a
                href={item.to}
                target="_blank"
                rel="noopener noreferrer"
              >
                {item.icon}
              </a>
            </li>
          ))}*/}
        </ul>
      </div>
    </Container>
  </div>
);
