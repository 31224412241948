// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---src-pages-404-tsx": () => import("/codebuild/output/src805301676/src/headways-digital/src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-index-tsx": () => import("/codebuild/output/src805301676/src/headways-digital/src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-nosotros-tsx": () => import("/codebuild/output/src805301676/src/headways-digital/src/pages/nosotros.tsx" /* webpackChunkName: "component---src-pages-nosotros-tsx" */),
  "component---src-pages-servicios-tsx": () => import("/codebuild/output/src805301676/src/headways-digital/src/pages/servicios.tsx" /* webpackChunkName: "component---src-pages-servicios-tsx" */)
}

exports.data = () => import(/* webpackChunkName: "pages-manifest" */ "/codebuild/output/src805301676/src/headways-digital/.cache/data.json")

